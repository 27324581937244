<template>
  <div class="wrapper">
    <!-- 活动记录添加/修改 -->
    <h4>{{ titleName }}</h4>
    <el-form :model="form" ref="form" label-width="120px" :disabled="isDetail">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="主办单位" prop="organizer">
            <el-input v-model="form.organizer" placeholder="请输入主办单位" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动名称" prop="actName">
            <el-input v-model="form.actName" placeholder="请输入活动名称" auto-complete="off" :disabled="isDetail"
              :class="fontColor"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动地点" prop="actAddress">
            <el-input v-model="form.actAddress" placeholder="请输入活动地点" auto-complete="off" :disabled="isDetail"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="活动开始时间" prop="actStartTime">
            <el-date-picker v-model="form.actStartTime" type="datetime" placeholder="选择日期时间" style="width:100%;"
              format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" :disabled="isDetail">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动结束时间" prop="actEndTime">
            <el-date-picker style="width:100%;" v-model="form.actEndTime" type="datetime" format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss" placeholder="选择日期时间" :disabled="isDetail">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动人数">
            <el-input v-model="form.actPeopleNum" placeholder="请输入活动人数" auto-complete="off"
              @input="(v) => (form.actPeopleNum = v.replace(/[^\d]/g, ''))" :disabled="isDetail"> </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="负责人姓名" prop="principalName">
            <el-input v-model="form.principalName" placeholder="请输入负责人姓名" auto-complete="off"
              :disabled="isDetail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="负责人电话" prop="principalPhone">
            <el-input v-model="form.principalPhone" placeholder="请输入负责人电话" auto-complete="off"
              @input="(v) => (form.principalPhone = v.replace(/[^\d]/g, ''))" :disabled="isDetail"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="协办者或协办单位" prop="coOrganizer">
            <el-input v-model="form.coOrganizer" placeholder="请输入协办者或协办单位" auto-complete="off"
              :disabled="isDetail"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="所需物资">
            <el-input v-model="form.requiredSubstance" type="textarea" placeholder="请输入所需物资" :disabled="isDetail"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="服务记录及特征">
            <el-input v-model="form.serviceObjectsCharacteristics" type="textarea" placeholder="请输入服务记录及特征"
              :disabled="isDetail" :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动具体内容">
            <el-input v-model="form.actContent" type="textarea" placeholder="请输入服务记录及特征" :disabled="isDetail"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>


      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="活动目的">
            <el-input v-model="form.actGoal" :disabled="isDetail" type="textarea" placeholder="请输入活动目的"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="活动成效">
            <el-input v-model="form.actEffectiveness" :disabled="isDetail" type="textarea" placeholder="请输入活动成效"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="注意事项">
            <el-input v-model="form.precautions" :disabled="isDetail" type="textarea" placeholder="请输入注意事项"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="志愿者福利">
            <el-input v-model="form.volunteerWelfare" :disabled="isDetail" type="textarea" placeholder="请输入志愿者福利"
              :autosize="{ minRows: 4, maxRows: 4 }" resize="none" auto-complete="off"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="所属区域" prop="tenant">
            <region-form ref="regionForm" v-model="form.tenant" />
          </el-form-item>
        </el-col>
      </el-row>


      <el-row :gutter="20" v-if="!isDetail">
        <el-col :span="8">
          <el-form-item label="选择服务人员" prop="serviceWorkPersonId">
            <el-input v-model="form.workPersonIdList" placeholder="请选择服务人员" :disabled="true" style="width:100%">
              <el-button type="primary" slot="append" icon="el-icon-search"
                @click="selectServiceWorker('worker')"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择服务对象">
            <el-input v-model="form.oldPersonIdList" placeholder="请选择服务对象" auto-complete="off" :disabled="true"
              style="width:100%">
              <el-button type="primary" slot="append" icon="el-icon-search"
                @click="selectServiceWorker('oldPeople')"></el-button>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20" v-if="isDetail">
        <el-col :span="8">
          <el-form-item label="选择服务人员" prop="serviceWorkPersonId">
            <el-input type='textarea' :autosize='{ minRows: 4, maxRows: 4 }' :class="fontColor" resize='none'
              v-model="form.workPersonIdList" placeholder="请选择服务人员" :disabled="true" style="width:100%">
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择服务对象">
            <el-input type='textarea' :class="fontColor" resize='none' :autosize='{ minRows: 4, maxRows: 4 }'
              v-model="form.oldPersonIdList" placeholder="请选择服务对象" auto-complete="off" :disabled="true"
              style="width:100%">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="活动图片">
            <upload-img :limit="6" :uploadLists="beforeImgsList" @uploadChange="onBeforeImg"></upload-img>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="m-footer">
      <el-button type="primary" icon="fa fa-check" @click="submitEvent('form')" v-if="!isDetail">保存</el-button>
      <el-button icon="fa fa-undo" @click="$router.go(-1)">返回</el-button>
    </div>


    <el-dialog :title="pupTitle" :visible.sync="visible" width="50%" :close-on-click-modal="false">
      <div>
        <el-form :model="searchData" ref="visibleform" label-width="auto">
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="姓名" prop="name">
                <el-input v-model="searchData.name" placeholder="请输入姓名" auto-complete="off"
                  :disabled="isDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="contTel">
                <el-input v-model="searchData.contTel" placeholder="请输入联系电话" auto-complete="off"
                  :disabled="isDetail"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="身份证号" prop="identityCards">
                <el-input v-model="searchData.identityCards" placeholder="请输入身份证号" auto-complete="off"
                  :disabled="isDetail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-form-item style="text-align: center" label-width="0">
              <el-button type="primary" @click="submitForm('visibleform')">搜索</el-button>
              <el-button @click="resetForm('visibleform')">重置</el-button>
            </el-form-item>
          </el-row>
        </el-form>
        <div style="margin-top:10px;">
          <el-table :data="servicePeopleTableData" ref="multipleTable" :key="servicePeopleTableData.id"
            highlight-current-row border size="medium" :row-key="getKey" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55" :reserve-selection="true">
            </el-table-column>
            <el-table-column prop="name" label="姓名" width="100">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleClick(scope.row)">{{ scope.row.name }}</el-button>
              </template>
            </el-table-column>
            <el-table-column prop="sex" label="性别" width="100"></el-table-column>
            <el-table-column prop="identityCards" label="身份证号" width="300"></el-table-column>
            <el-table-column prop="contTel" label="联系方式" width="200"></el-table-column>
            <el-table-column prop="address" label="地址" width="396"></el-table-column>


          </el-table>
          <div style="text-align: right; margin-top: 10px;">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="tablePage.currentPage" :page-sizes="[5, 10, 15]" :page-size="tablePage.pageSize"
              layout="total,jumper,prev, pager, next,sizes" :total="tablePage.totalResult"></el-pagination>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel()">清 空</el-button>
        <el-button type="primary" @click="sureSubmit()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapActions
} from "vuex";
import UploadImg from '@/components/UploadImg.vue'
import RegionForm from '@/components/RegionForm.vue'

export default {
  name: 'AddActivityRecord',
  components: {
    UploadImg,
    RegionForm
  },
  data() {
    var validTel = (rule, value, callback) => {
      if (value && !this.$regex.telRegEx(this.form.tel)) {
        callback(new Error('联系方式格式不正确'));
      } else {
        callback();
      }
    };
    return {
      isDetail: false,
      titleName: '',
      form: {
        actImgs: ''
      },
      beforeImgsList: [],
      workPersonIdListParam: [],
      workPersonNameList: [],
      oldPeopleIdListParam: [],
      oldPeopleNameList: [],
      pupTitle: '',
      searchData: {
        name: '',
        contTel: '',
        identityCards: ''
      },
      visible: false,
      tablePage: {
        totalResult: 0,
        currentPage: 1,
        pageSize: 5,
        align: 'left',
        pageSizes: [10, 20, 50, 100, 500, 1000],
        perfect: true,
      },
      servicePeopleTableData: [
        { orgName: 1, tel: 13011111111 },
        { orgName: 2, tel: 13011122211 }

      ],
      pupType: '',
      multipleSelection: [],
      workPersonList: [], //详情从业人员 table表 返回值
      oldPersonList: [], // 老人详情 table表返回值
      arr: [],
      fontColor: ''
      // routeType:''
    }
  },
  computed: {
    routeType() {
      return this.$route.params.type
    }
  },
  created() {
    this.initView()
    // this.getProvincesList()
    // this.initMap()
  },
  methods: {
    ...mapActions([
        'downloadFile',
      "addServeRecordsServe",
      "getWorkPersonPup",//从业人员查询(弹窗)
      "searchPerson",//老人查询（弹窗）
      "addActRecord",//添加活动记录
      "actDetail",//服务记录详情
      'updataActRecord'//修改

    ]),
    /**
         * 获取row-key
         */
    getKey(row) {
      return row.id
    },

    //从业人员查询(弹窗)
    getConsConsultant() {
      this.getWorkPersonPup({
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          name: this.searchData.name,
          contTel: this.searchData.contTel,
          identityCards: this.searchData.identityCards
        },
      }).then((res) => {
        if (res.code == 200) {
          this.servicePeopleTableData = res.data.records
          // if(this.workPersonthisList!=[]){
          //   console.log(.workPersonList);
          //   this.workPersonList.map(item=>{
          //     res.data.records.map((dataitem,index)=>{
          //       if(item.id==dataitem.id){
          //        this.arr.push(res.data.records[index])
          //       }
          //     })
          //   })
          //   console.log(arr);
          //   if(this.arr.length!=0){
          //      this.arr.forEch(row => {
          //       this.$refs.multipleTable.toggleRowSelection(row);
          // });
          //   }a
          //   //  this.$refs.multipleTable.toggleRowSelection(arr);
          // }
          this.tablePage.totalResult = +res.data.total
        }
        //this.loading = false
      })
    },

    //老人（弹窗）查询
    getOldPerson() {
      let params = {
        current: this.tablePage.currentPage,
        size: this.tablePage.pageSize,
        param: {
          personName: this.searchData.name,
          idNumber: this.searchData.identityCards,
          tel: this.searchData.contTel,
        },
      }
      let data = null
      let server = 'searchPerson'
      this[server](params).then((res) => {
        if (res.code == 200) {
          let arr = []
          res.data.records.map(item => {
            item.identityCards = item.idNum
            item.contTel = item.tel
          })
          //  if(this.oldPersonList!=[]){
          //   this.oldPersonList.map(item=>{
          //     res.data.records.map((dataitem,index)=>{
          //       if(item.id==dataitem.id){
          //        arr.push(res.data.records[index])

          //       }
          //     })
          //   })
          //   if(arr.length!=0){
          //      arr.forEach(row => {
          //       this.$refs.multipleTable.toggleRowSelection(row);
          // });
          //   }
          //   //  this.$refs.multipleTable.toggleRowSelection(arr);
          // }
          this.servicePeopleTableData = res.data.records
          this.tablePage.totalResult = +res.data.total
        }
      })
    },
    //点击姓名跳转
    handleClick(row) {
      if (this.pupType == 'oldPeople') {
        console.log(this.$route);
        this.$router.push({
          name: 'AddPerson',
          params: {
            type: 'detail',
            id: row.id,
            tab: 1
          }
        })
      } else if (this.pupType == 'worker') {
        this.$router.push({
          name: 'WorkPersonDetail',
          params: {
            type: 'detail',
            id: row.id,
            tab: 1
          },
          query: null
        })
      }

    },


    initView() {
      if (this.routeType == 'add') {
        this.titleName = '添加活动记录'
      } else if (this.routeType == 'detail') {
        this.isDetail = true
        this.titleName = '活动记录详情'
        this.fontColor = 'blue'
        this.getDetail()
      } else {
        this.titleName = '修改活动记录'
        //这里调用请求编辑时的详情接口渲染form表单数据
        this.getDetail()

      }
    },
    // 查服务记录详情
    getDetail() {

      let params = {
        id: this.$route.query.rowData.id,
      }
      this.actDetail(params).then((res) => {
        if (res.code == 200) {

          this.form = {
            id: this.$route.params.id,
            ...res.data,
            tenant: res.data.tenant.split(',').slice(2)
          }

          this.$nextTick(() => {
            this.$refs.regionForm.initRegion()
          })


          let workerArr = []
          let oldpersonArr = []
          this.workPersonList = res.data.workPersonList
          if (res.data.workPersonList) {
            res.data.workPersonList.map(item => {
              workerArr.push(item.name),
                this.workPersonIdListParam.push(item.id)
            })
          }

          this.oldPersonList = res.data.oldPersonList
          if (res.data.oldPersonList) {
            res.data.oldPersonList.map(item => {
              oldpersonArr.push(item.name),
                this.oldPeopleIdListParam.push(item.id)
            })
          }
          this.form.workPersonIdList = workerArr.toString().split(',').join(',  ')
          this.form.oldPersonIdList = oldpersonArr.toString().split(',').join(',  ')
          // 如果图片存在，查图片详情
          if (this.form.actImgs) {
            this.getFileDetail(this.form.actImgs)
          }
        }
      })
    },
    getFileDetail(ids) {
      ids = ids.split(',')
      ids.forEach(item => {
        this.downloadFile(item).then(res => {
          this.beforeImgsList.push({
            id: item,
            url: window.URL.createObjectURL(res.data)
          })
        })
      })
    },
    onBeforeImg(data) {
      this.form.actImgs = data.join(',')
    },
    // 获取数据字典
    getSysDictList(code, codeList) {
      let params = {
        typeCode: code
      }
      this.getSysDictServe(params).then(res => {
        if (res.code == 200) {
          this[codeList] = res.data
        }
      })
    },
    submitEvent(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.routeType == 'add') {
            let params = {
              id: this.$route.query.rowData ? this.$route.query.rowData.id : null,
              cmOrEntId: JSON.parse(this.$route.query.item).id,
              actCategory: JSON.parse(this.$route.query.item).entCmType,
              organizer: this.form.organizer,
              actName: this.form.actName,
              actAddress: this.form.actAddress,
              actStartTime: this.form.actStartTime,
              actEndTime: this.form.actEndTime,
              actPeopleNum: this.form.actPeopleNum,
              principalName: this.form.principalName,
              principalPhone: this.form.principalPhone,
              coOrganizer: this.form.coOrganizer,
              requiredSubstance: this.form.requiredSubstance,
              serviceObjectsCharacteristics: this.form.serviceObjectsCharacteristics,
              actContent: this.form.actContent,
              actGoal: this.form.actGoal,
              actEffectiveness: this.form.actEffectiveness,
              precautions: this.form.precautions,
              volunteerWelfare: this.form.volunteerWelfare,
              workPersonIdList: this.workPersonIdListParam,
              oldPersonIdList: this.oldPeopleIdListParam,
              actImgs: this.form.actImgs
            }
            params.tenant = `1,101,${this.form.tenant.join(',')}`
            this.addActRecord(params).then(res => {
              if (res.code == 200) {
                this.$XModal.message({
                  message: '添加成功',
                  status: "success"
                });
                this.$router.go(-1)
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            })
          } else {
            let params = {
              id: this.$route.query.rowData ? this.$route.query.rowData.id : null,
              cmOrEntId: JSON.parse(this.$route.query.item).id,
              actCategory: JSON.parse(this.$route.query.item).entCmType,
              organizer: this.form.organizer,
              actName: this.form.actName,
              actAddress: this.form.actAddress,
              actStartTime: this.form.actStartTime,
              actEndTime: this.form.actEndTime,
              actPeopleNum: this.form.actPeopleNum,
              principalName: this.form.principalName,
              principalPhone: this.form.principalPhone,
              coOrganizer: this.form.coOrganizer,
              requiredSubstance: this.form.requiredSubstance,
              serviceObjectsCharacteristics: this.form.serviceObjectsCharacteristics,
              actContent: this.form.actContent,
              actGoal: this.form.actGoal,
              actEffectiveness: this.form.actEffectiveness,
              precautions: this.form.precautions,
              volunteerWelfare: this.form.volunteerWelfare,
              workPersonIdList: this.workPersonIdListParam,
              oldPersonIdList: this.oldPeopleIdListParam,
              actImgs: this.form.actImgs
            }
            this.updataActRecord(params).then(res => {
              if (res.code == 200) {
                this.$XModal.message({
                  message: '修改成功',
                  status: "success"
                });
                this.$router.go(-1)
              } else {
                this.$message.error(res.msg);
              }
              this.loading = false;
            })
          }
        } else {
          return false;
        }

      });

    },
    /**
      * 选择服务人员
      */
    selectServiceWorker(type) {
      this.pupType = type
      this.visible = true
      if (type == 'worker') {
        this.getConsConsultant()
        this.workPersonIdListParam = []
        this.workPersonNameList = []
        this.pupTitle = "选择服务人员"

      } else if (type == 'oldPeople') {
        this.getOldPerson()
        this.oldPeopleIdListParam = [],
          this.oldPeopleNameList = []
        this.pupTitle = "选择服务对象"

      }

    },
    handleSizeChange(size) {
      this.tablePage.pageSize = size
      if (this.pupType == 'worker') {
        this.getConsConsultant()
      } else if (this.pupType == 'oldPeople') {
        this.getOldPerson()
      }
    },
    handleCurrentChange(currentPage) {
      this.tablePage.currentPage = currentPage
      if (this.pupType == 'worker') {
        this.getConsConsultant()
      } else if (this.pupType == 'oldPeople') {
        this.getOldPerson()
      }
    },
    submitForm() {
      if (this.pupType == 'worker') {
        this.getConsConsultant()
      } else if (this.pupType == 'oldPeople') {
        this.getOldPerson()
      }
      // this.getConsConsultant()
    },
    //弹窗搜索重置
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    handleSelectionChange(val) {
      // this.multipleSelection = val.map(item => item.id)
      this.multipleSelection = []
      for (let i = 0; i < val.length; i++) {
        if (this.multipleSelection.indexOf(val[i]) === -1) {
          this.multipleSelection.push(val[i]);
        }
      }
    },
    //弹窗确认
    sureSubmit() {
      if (this.pupType == 'worker') {
        this.multipleSelection.map((item, index) => {
          this.workPersonIdListParam.push(item.id)

          this.workPersonNameList.push(item.name)
        })
        this.form.workPersonIdList = this.workPersonNameList.toString()

      } else if (this.pupType == 'oldPeople') {
        this.multipleSelection.map((item, index) => {
          this.oldPeopleIdListParam.push(item.id)
          this.oldPeopleNameList.push(item.name)
        })
        this.form.oldPersonIdList = this.oldPeopleNameList.toString()
      }
      this.visible = false
    },
    cancel() {
      this.multipleSelection = []
      this.visible = false
      this.$refs.multipleTable.clearSelection();

      if (this.pupType == 'worker') {
        this.form.workPersonIdList = []
      } else if (this.pupType == 'oldPeople') {
        this.form.oldPersonIdList = []
      }

    },

  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 10px;
  height: 100%;
  overflow: height;
  display: flex;
  flex-direction: column;

  h4 {
    height: 40px;
    line-height: 40px;
    color: #333;
    padding-left: 20px;
    margin-bottom: 20px;
  }

  .el-form {
    padding: 10px;
    flex: 1;
    overflow: auto;
  }

  .vxe-form {
    padding: 25px 0;
    overflow: auto;
  }

  .my-dropdown1 {
    height: 200px;
    overflow: auto;
    border-radius: 4px;
    background-color: #fff;
    border: 1px solid #dcdfe6;
    padding: 0 8px;

    li {
      height: 32px;
      line-height: 32px;
      cursor: pointer;
    }
  }

}

::v-deep .blue .el-input__inner {
  color: #2d8cf0 !important;
}

::v-deep .blue .el-textarea__inner {
  color: #2d8cf0 !important;
}
</style>
